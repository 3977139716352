// // src/components/Recorder.js
import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, storage } from './firebase-config'; // Import Firebase storage
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL, getStorage, listAll } from "firebase/storage";
import { signOut } from 'firebase/auth';
import './App.css';
import './chatbot.css';
import {ProgressBar, ProgressBarv2} from './Logosvg';
import PermCameraMicRounded from '@mui/icons-material/PermCameraMicRounded';
import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import { enableCamera, enableScreenSharing } from './functions';


function Recorder({isRecordingStart, setRecordingStart, cameraEnabledv2,
    setCameraEnabledv2,micEnabledv2, setMicEnabledv2,screenEnabledv2, setScreenEnabledv2, 
    iscamRecordingv2, setIscamRecordingv2, cameraButton, setCameraButton, screenButton, setScreenButton, 
    recordButton, setrecordButton, interviewEnded, setInterviewEnded,
    uploadProgress, setUploadProgress, isUploading, setIsUploading}) {
    const user = auth.currentUser;
    const {jobId} = useParams();
    const userId = user.uid;
    const userEmail = user.email;
    const webcamRef = useRef(null);
    const screenRef = useRef(null);
    const navigate = useNavigate();
    const [iscamRecording, setIscamRecording] = useState(false);
    const [videoRecorder, setVideoRecorder] = useState(null);
    const [screenStream, setScreenStream] = useState(null);
    const [cameraEnabled, setCameraEnabled] = useState(false);
    const [micEnabled, setMicEnabled] = useState(false);
    const [screenEnabled, setScreenEnabled] = useState(false);
    const [screenRecorder, setScreenRecorder] = useState(null);
    // const [uploadProgress, setUploadProgress] = useState(0);
    // const [isUploading, setIsUploading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    

    // const enableCamera = () => {
    //     // Assuming camera and mic are from the same stream if both are used together
    //     setCameraEnabled(true);
    //     setMicEnabled(true);  // You might separate these if needed
    //     setCameraButton(true);
    // };

    const enableCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                // Permissions granted, and stream is available
                setCameraEnabled(true);
                setMicEnabled(true);
                // If you are using refs to store the stream, you can do it here
                if (webcamRef.current && 'srcObject' in webcamRef.current) {
                    webcamRef.current.srcObject = stream;
                }
            })
            .catch((error) => {
                // Handle the error case
                console.error("Error accessing the media devices.", error);
                alert('Unable to access camera and microphone. Please allow access to camera and microphone.'); // Inform the user
            });
    };

    useEffect(() => {
        if (cameraEnabledv2,micEnabledv2) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                // Permissions granted, and stream is available
                setCameraEnabled(true);
                setMicEnabled(true);
                // If you are using refs to store the stream, you can do it here
                if (webcamRef.current && 'srcObject' in webcamRef.current) {
                    webcamRef.current.srcObject = stream;
                }
            })
            .catch((error) => {
                // Handle the error case
                setCameraButton(false);
                console.error("Error accessing the media devices.", error);
                alert('Unable to access camera and microphone. Please allow access to camera and microphone.'); // Inform the user
                
            });
            setCameraEnabledv2(false);
            setMicEnabledv2(false);
        }
    });

    const enableScreenSharing = async () => {
        try {
            if(!screenEnabled) {
                const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
                if (stream && stream.getVideoTracks().length > 0) {
                    setScreenStream(stream);
                // if (screenRef) {
                //     screenRef.srcObject = screenStream;
                // }
                    setScreenEnabled(true);
                    setScreenButton(true);
                    // setIsScreenShare(true);
                } else {
                    setScreenEnabled(false);
                    setScreenButton(true);
                    // setIsScreenShare(false);
                    alert("Failed to capture screen. Please try again.")
                }
            }

        } catch (error) {
            console.error("Failed to get screen stream:", error);
        }
    };

    useEffect (() => {
        if (screenEnabledv2) {
            enableScreenSharing();
            setScreenEnabledv2(false);
        }
    });

    useEffect(() => {
        if (iscamRecordingv2) {
            startRecording();
            setIscamRecordingv2(false);
        }
    });

    useEffect(() => {
        if (interviewEnded) {
            stopRecording();
        }
    });

    // Effect to check if the stream is still active
    useEffect(() => {
        if (screenStream) {
            const checkStream = setInterval(() => {
                if (!screenStream.active || screenStream.getVideoTracks().length === 0) {
                    setScreenEnabled(false);
                    setScreenButton(false);
                    clearInterval(checkStream);
                }
            }, 1000);
            return () => clearInterval(checkStream);
        }
    }, [screenStream]);

    const startRecording = async () => {
        // Get webcam stream
        // const testBlob = new Blob(['Hello, world!'], {type: 'text/plain'});
        // uploadToFirebase(testBlob, 'testFile.txt');
        // const videoStream = webcamRef.current.stream;

        // Request screen capture
        console.log("recording begin")
        try {
             if (micEnabled && cameraEnabled && screenEnabled) {
                const videoStream = webcamRef.current.stream;
                // setup recorders
                const videoOptions = {
                    mimType: 'video/webm; codecs=vp9', 
                    bitsPerSecond: 264 * 1000, // Reduce bitrate. For example, 512kbps
                    video: {
                        width: 320,
                        height: 240
                    }
                }
                const screenOptions = {
                    mimType: 'video/webm; codecs=vp9',
                    bitsPerSecond: 64 * 1000, // Reduce bitrate. For example, 512kbps
                    video: {
                        width: 160,
                        height: 120
                    }
                }

                const videoRTC = new RecordRTC(videoStream, videoOptions);
                const screenRTC = new RecordRTC(screenStream, screenOptions);

                videoRTC.startRecording();
                screenRTC.startRecording();

                setRecordingStart(true);
                setVideoRecorder(videoRTC);
                setScreenRecorder(screenRTC);
                setIscamRecording(true);
                setrecordButton(true);
                
            } else {
                setrecordButton(false);
                alert("Mic, Camera or Screen is/are not enabled")
            }
        } catch (error) {
            console.error('Failed to capture screen:', error);
        }
    };

    const stopRecording = async () => {
        if (videoRecorder) {
            videoRecorder.stopRecording(() => {
                const blob = videoRecorder.getBlob();
                console.log('Video blob created', blob);
                if (blob.size > 0) {
                    uploadToFirebase(blob, 'webcamRecording');
                } else {
                    console.log("Blob is empty, check recording setup")
                }
            });
            
        }

        if (screenRecorder) {
            screenRecorder.stopRecording(() => {
                const blob = screenRecorder.getBlob();
                console.log('Screen blob created', blob);
                uploadToFirebase(blob, 'screenRecording');
            });
        }
        setIscamRecording(false);
        setRecordingStart(false);
        setMicEnabled(false);
        setCameraEnabled(false);
        setScreenEnabled(false);
        setInterviewEnded(true);
    };

    async function getNextAvailableFilename(storageRef, baseName, index = 0) {
        const fileName = index === 0 ? `${baseName}.webm` : `${baseName}${index}.webm`;
        // const fileRef = ref(storageRef, fileName);
    
        const listResult = await listAll(storageRef);
        const fileNames = listResult.items.map(item => item.name);
    
        if (fileNames.includes(fileName)) {
            return getNextAvailableFilename(storageRef, baseName, index + 1);
        } else {
            return fileName;
        }
    }

    // Utility to upload files to Firebase Storage
    async function uploadToFirebase (blob, baseName) {
        const storageRef = ref(storage, `uploads/${jobId}/${userEmail}`); // Folder named 'uploads'
        // const storageRef = ref(storage, `uploads/${jobId}/${userId}/${fileName}`)
        const metadata = {
            contentType: blob.type,  // Use the MIME type from the file Blob object
        };

        const availableFileName = await getNextAvailableFilename(storageRef, baseName);

        const fileRef = ref(storage, `uploads/${jobId}/${userEmail}/${availableFileName}`);
        const uploadTask = uploadBytesResumable(fileRef, blob, metadata);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setIsUploading(true);
                setUploadProgress(progress);
                console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
                // Handle unsuccessful uploads
                console.error('Upload failed:', error);
            }, 
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log('File available at', downloadURL);
                    setDownloadUrl(downloadURL);
                });
            }
        );
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("User logged out successfully");
            navigate(`/${jobId}/login`)
            stopRecording();
        }).catch((error) => {
            // An error happened.
            console.error("Error logging out: ", error);
        });
    };

    return (
        <div className='record-contentv2' style={{margin:'10px', marginLeft:'0px'}}>
            
            <div className='content'>
            {
                iscamRecording ? <RadioButtonCheckedIcon className={`${iscamRecording ? 'blip' : ''}`} style={{width:'10%',marginTop:'15px', marginRight:'0', color:`${iscamRecording ? 'red' : 'black'}`}}/> 
                : isUploading && uploadProgress>0 ?
                <ProgressBarv2 progress={uploadProgress} /> :
                <RadioButtonCheckedIcon className={`${iscamRecording ? 'blip' : ''}`} style={{width:'10%',marginTop:'15px', marginRight:'0', color:`${iscamRecording ? 'red' : 'black'}`}}/>
                
                 
            }
            <button className='submit-button' style={{marginLeft:'0px'}} onClick={iscamRecording ? stopRecording : startRecording}>
                    {iscamRecording ? 'Stop Interview' : `Start Interview`}
            </button>
            <button className='submit-button' style={{marginLeft:'0px'}} onClick = {handleLogout}>
                Log Out
            </button>
            
            
            </div>
            <div className='instructions'>
                <h4>Instructions</h4>
                <ol>
                    <li>Turn on Webcam and Mic</li>
                    <li>Share your Entire Screen</li>
                    <li>Start Recording the Interview</li>
                    <li>Once you're done with the above 3 steps. Click on the mic button and say 'Hi I am ready for my interview'</li>
                    <li>Questions will be prompted on the screen. You have to answer these question by UnMuting yourself using mic button at the bottom and once you are done answering the question, mute again to submit your answer and wait for the next question Click on the mic button and say 'Hi I am ready for my interview'</li>
                </ol>
            </div>
            <div className='camera'>
                {!cameraEnabled && (
                    <button className='submit-button' onClick={enableCamera}>Camera</button>
                )}
                {cameraEnabled && (
                    <Webcam 
                        ref={webcamRef} 
                        audio={true} 
                        style={{ width: '110%', margin:'0px', padding:'0px', objectFit:'cover'}}
                        // className='camera'
                        muted = "muted"
                        videoConstraints={{ facingMode:'user'}}
                    />
                    
                )}
                
            </div>
            {/* {uploadProgress > 0 && (
                <div>
                    <progress value={uploadProgress} max="100"></progress>
                    <span>{Math.round(uploadProgress)}%</span>
                </div>
            )} */}
            
            <div className='camera' style={{marginTop:'10px'}}>
                {!screenEnabled && (
                    <button className='submit-button' onClick={enableScreenSharing}>Screen Shared</button>
                )}
                {screenEnabled && (
                    <video 
                        autoPlay 
                        ref={elem => {
                            if (elem) {
                                elem.srcObject = screenStream;
                            } 
                        }}
                        style={{ width: '110%' }}
                        muted = "muted"
                    />
                )}
            </div>
        </div>
    );
}

export {enableCamera,Recorder, enableScreenSharing} ;
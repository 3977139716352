import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useParams } from 'react-router-dom';
import EntryForm from './entryForm';  // Ensure the path is correct
import ChatBot from './chatBotv2';
import { auth } from './firebase-config';
import Login from './loginv2.js'

function ProtectedRoute({ children }) {
  const user = auth.currentUser;
  const { jobId } = useParams();
  return user ? children : <Navigate to={`/${jobId}/login`} />;
}

function App() {
    const [isRecordingStart, setRecordingStart] = useState(false);
    const [cameraEnabledv2,setCameraEnabledv2] = useState(false);
    const [micEnabledv2, setMicEnabledv2] = useState(false);
    const [screenEnabledv2, setScreenEnabledv2] = useState(false);
    const [iscamRecordingv2, setIscamRecordingv2] = useState(false);
    const [cameraButton, setCameraButton] = useState(false);
    const [screenButton, setScreenButton] = useState(false);
    const [recordButton, setrecordButton] = useState(false);
    const [interviewEnded, setInterviewEnded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    return (
        // <Router>
          <Routes>
            <Route path="/:jobId" element={<Login/>} exact />
            <Route path="/:jobId/login" element={<Login/>} exact />
            <Route path="/:jobId/entry-form" element={<ProtectedRoute><EntryForm/></ProtectedRoute>} exact />
            <Route path="/:jobId/interview" element={<ProtectedRoute><ChatBot/></ProtectedRoute>} exact />
            {/* <Route path="/" element={<Navigate replace to="/entry-form"/>} />  // Default redirect to the form */}
          </Routes>
        /* // </Router> */
    );
}

export default App;
import React, { useState, useEffect }from 'react';
import './App.css';
import { ReactComponent as Blob } from './logo1.svg';
import { ReactComponent as Mic } from './mic.svg';
import { ReactComponent as Logo2 } from './logo2.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Lottie from 'react-lottie';
import animationData from './animation.json'; 
import { green } from '@mui/material/colors';

function LogoSVG() {
  return <div className="blob-container">
    <Blob className="blob"/>
  </div>;
};

function LogoSVG2() {
  return <div>
    <Logo2 style={{width: '100px', height: '100px'}}/>
  </div>;
};

function Dropdown() {
  const [isOpen, setIsOpen] = useState(true);
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
      <div className="dropdown-container">
          <button onClick={toggleDropdown} className="dropdown-toggle">
              {isOpen ? 'Hide Instructions' : 'Show Instructions'}
          </button>
          {isOpen && (
              <div className="chat-container">
                  <ul>
                      <li>Step 1: "Enable webcam and mic" using the top left corner button and make sure you're clearly visible with No filters/background</li>
                      <li>Step 2: Share your entire screen using the button on top right corner</li>
                      <li>Step 3: Once you're done with step 1 and 2 click on "Start Recording Interview" just above the "Hide/Show Instructions" button</li>
                      <li>Step 4: Once step 3 is completed a mic will appear at the bottom. Unmute yourself using the mic button at the bottom and say "Hi, I am ready for the interview" then mute again to submit.</li>
                      <li>Step 5: Questions will be prompted on the screen. You have to answer these question by UnMuting yourself using mic button at the bottom and once you are done answering the question, mute again to submit your answer and wait for the next question</li>
                      <li>Step 6: Once you're done with these steps, you can hide the instructions and start your exam</li>
                  </ul>
              </div>
          )}
      </div>
  );
};

function LottieAnimation() {
  const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice' // Supports the aspect ratio of the animation
      }
  };

  return (
      <div>
          <Lottie options={defaultOptions} height={100} width={100} />
      </div>
  );
};


function micSVG() {
  return <div className="mic-container">
    <Mic className="mic"/>
  </div>;
}

function ProgressBar({ progress }) {
  return (
      <div style={{ display: 'flex', position: 'absolute', width: '100%' }}>
          <progress value={progress} max="100" style={{ width: '100%' }}></progress>
          <span style={{
              position: 'absolute',
              display: 'flex',
              left: '50%', // Center horizontally
              top: '1%', // Center vertically
              transform: 'translate(-50%, -50%)', // Ensure exact centering
              color: 'white', // Text color
              fontSize: '1rem' // Text size
          }}>
              {progress < 100 ? `Uploading... ${Math.round(progress)}%` : "Uploaded"}
          </span>
      </div>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

function ProgressBarv2({ progress }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
      {progress < 100 ? (
        <span>
        <CircularProgressWithLabel value={progress} />
          <p style={{fontSize:'8px', marginTop:'0'}}>Uploading</p>
        </span>
      ) : (
        <span style={{color:'green'}}>
          <CheckCircleOutlineIcon style={{marginBottom:'0'}}/>
          <p style={{fontSize:'8px', marginTop:'0'}}>Uploaded</p>
        </span>
        // <p style={{fontSize:'8px'}}>Uploaded</p>
      )}
    </div>
  );
};

function FluentMic32Regular(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 32 32" {...props}><path fill="black" d="M16 2a6 6 0 0 0-6 6v8a6 6 0 0 0 12 0V8a6 6 0 0 0-6-6m4 14a4 4 0 0 1-8 0V8a4 4 0 0 1 8 0zM7 15a1 1 0 0 1 1 1a8 8 0 1 0 16 0a1 1 0 1 1 2 0c0 5.186-3.947 9.45-9.001 9.95L17 26v3a1 1 0 1 1-2 0v-3l.001-.05C9.947 25.45 6 21.187 6 16a1 1 0 0 1 1-1"></path></svg>);
};

class CustomWave extends React.Component {
  render() {
    const { speaking } = this.props;
    const animateSpeed = speaking ? "3s" : "0s";

    return (
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
        <path fill="#00BFFF" d="
          M0,100 Q250,${speaking ? 0 : 100} 500,100 
          V150 H0 V100 Z">
          <animate
            attributeName="d"
            values="
              M0,100 Q250,0 500,100 V150 H0 V100 Z;
              M0,100 Q250,200 500,100 V150 H0 V100 Z;
              M0,100 Q250,0 500,100 V150 H0 V100 Z"
            repeatCount="indefinite"
            dur={animateSpeed}
          />
        </path>
      </svg>
    );
  }
};

const WaveCircle = ({ speaking }) => {
  const radius = 100;  // Circle radius
  const numWaves = 10; // Number of waves
  const waveAmplitude = 10; // Amplitude of the wave peaks
  const waveFrequency = 20; // Frequency of waves
  const viewBoxSize = 2 * (radius + waveAmplitude);
  const viewBox = [-viewBoxSize / 2, -viewBoxSize / 2, viewBoxSize, viewBoxSize].join(' ');

  const [amplitude, setAmplitude] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAmplitude(prevAmp => (prevAmp < 20 ? prevAmp + 2 : 0));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const drawWavePath = () => {
    let pathD = "";
    const totalPoints = 360;
    const angleStep = 360 / totalPoints;

    for (let i = 0; i <= totalPoints; i++) {
      const angleInRadians = (i * angleStep * Math.PI) / 180;
      const x = (radius + waveAmplitude * Math.sin(waveFrequency * angleInRadians)) * Math.cos(angleInRadians);
      const y = (radius + waveAmplitude * Math.sin(waveFrequency * angleInRadians)) * Math.sin(angleInRadians);

      if (i === 0) {
        pathD += `M${x} ${y} `;
      } else {
        pathD += `L${x} ${y} `;
      }
    }

    return pathD + 'Z';
  };

  return (
    <svg width="300" height="300" viewBox={viewBox}>
      <path d={drawWavePath(amplitude)} fill="transparent" stroke="#00BFFF" strokeWidth="3" />
    </svg>
  );
};

export {LogoSVG, LogoSVG2, Dropdown, 
  ProgressBar, FluentMic32Regular, CustomWave, 
  WaveCircle, CircularProgressWithLabel, ProgressBarv2, 
  LottieAnimation}; 